import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Table, Input } from "antd";
import "./styles.scss";
import limpeza from "../../assets/products/limpeza-azul.png";
import expediente from "../../assets/products/clips-azul.png";
import Shield from "../../assets/global/escudoWhite.png";
import Whatsapp from "../../assets/global/whatsappWhite.png";
import FaviconFox from "../../assets/global/FaviconFox.png";
import PartnerStore from "../PartnerStore";
import Diversos from "../../assets/products/diversos.png";
import axios from "axios";
import InformationCard from "../InformationCard";
import FixedCart from "../FixedCart";
import { Spin } from "antd";

const { Search } = Input;

function ProductsBody() {
  const location = useLocation(); // Hook para acessar a URL atual e query string
  const queryParams = new URLSearchParams(location.search);
  const initialTypeProduct = queryParams.get("typeProduct") || "limpeza"; // Define o tipo de produto inicial com base na query string

  const [typeProduct, setTypeProduct] = useState(initialTypeProduct); // limpeza ou expediente ou diversos
  const [selectedProducts, setSelectedProducts] = useState([]); // Armazena key e produto
  const [filteredData, setFilteredData] = useState(null);
  const [limpezaData, setLimpezaData] = useState([]);
  const [diversosData, setDiversosData] = useState([]);
  const [expedienteData, setExpedienteData] = useState([]);
  const [firstUpdatePage, setFirstUpdatePage] = useState(true);

  const [sendingOrder, setSendingOrder] = useState(false);

  const url = "https://api.foxsuprimentos.com.br/";
  // const url = "http://localhost:8081/"

  const fetchData = async () => {
    try {
      const limpezaResponse = await axios.get(`${url}limpeza`, {
        headers: {
          Authorization: "Basic RGVzZW52b2x2aW1lbnRvRm94OjEyM0BGb3hEZXY=",
        },
      });

      const expedienteResponse = await axios.get(`${url}expediente`, {
        headers: {
          Authorization: "Basic RGVzZW52b2x2aW1lbnRvRm94OjEyM0BGb3hEZXY=",
        },
      });

      const diversosResponse = await axios.get(`${url}diversos`, {
        headers: {
          Authorization: "Basic RGVzZW52b2x2aW1lbnRvRm94OjEyM0BGb3hEZXY=",
        },
      });


      setLimpezaData(limpezaResponse.data);
      setExpedienteData(expedienteResponse.data);
      setDiversosData(diversosResponse.data);

      if (initialTypeProduct === "limpeza") {
        setFilteredData(limpezaResponse.data);
      } else {
        setFilteredData(expedienteResponse.data);
      }
    } catch (error) {
      console.error("Erro ao buscar os dados:", error);
    }
  };

  const goToWhatsapp = (idPedido) => {
    const numeroTelefone = 81988592115;
    var url = `https://api.whatsapp.com/send/?phone=+55${numeroTelefone}&text=Olá,+Acabei+de+fazer+o+pedido+número+${idPedido}+pelo+site+e+gostaria+de+mais+informações`;
    window.open(url, "_blank");
  };

  const finalizeOrder = () => {
    if (selectedProducts.length) {
      setSendingOrder(true);
      const productsJson = selectedProducts.map((product) => {
        return { fid: product.key, quantidade: product.quantidade };
      });

      var jsonBody = JSON.stringify({produtos: productsJson});

      axios
        .post(`${url}pedido`, jsonBody, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          goToWhatsapp(response.data);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setSendingOrder(false);
          setSelectedProducts([]);
        });
    }
  };

  useEffect(() => {
    fetchData();

    const storedSelectedProducts = localStorage.getItem("selectedProducts");
    if (storedSelectedProducts) {
      setSelectedProducts(JSON.parse(storedSelectedProducts));
    }
    setFirstUpdatePage(false);
  }, []);

  useEffect(() => {
    if (!firstUpdatePage) {
      localStorage.setItem("selectedProducts", JSON.stringify(selectedProducts));
    }
  }, [selectedProducts]);

  useEffect(() => {
    if (typeProduct === "limpeza") {
      setFilteredData(limpezaData);
    } else if(typeProduct === "expediente") {
      setFilteredData(expedienteData);
    } else {
      setFilteredData(diversosData);
    }
  }, [typeProduct]);

  const removeItem = (itemKey) => {
    const newSelectedProducts = selectedProducts.filter((product) => product.key !== itemKey);
    setSelectedProducts(newSelectedProducts);
    localStorage.setItem("selectedProducts", JSON.stringify(newSelectedProducts)); // Atualiza o localStorage com a lista de itens atualizada
  };

  const changeTypeProduct = (name) => {
    setTypeProduct(name);
  };

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();

    if (typeProduct === "limpeza") {
      const filtered = limpezaData.filter((item) => item.nome.toLowerCase().includes(value));
      setFilteredData(filtered);
    } else if (typeProduct === "expediente") {
      const filtered = expedienteData.filter((item) => item.nome.toLowerCase().includes(value));
      setFilteredData(filtered);
    } else if (typeProduct === "diversos") {
      const filtered = diversosData.filter((item) => item.nome.toLowerCase().includes(value));
      setFilteredData(filtered);
    }
  };

  const handleChangeQuantity = (item, value) => {
    setSelectedProducts((prevSelectedProducts) =>
      prevSelectedProducts.map((product) =>
        product.key === item.key ? { ...product, quantidade: Number(value) } : product
      )
    );
  };

  const handleRowClick = (record) => {
    const newSelectedProducts = [...selectedProducts];
    const productExists = newSelectedProducts.find((product) => product.key === record.key);

    if (productExists) {
      setSelectedProducts(newSelectedProducts.filter((product) => product.key !== record.key));
    } else {
      newSelectedProducts.push({ key: record.key, nome: record.nome, quantidade: 1 });
      setSelectedProducts(newSelectedProducts);
    }
  };

  const rowSelection = {
    selectedRowKeys: selectedProducts.map((product) => product.key),
    onSelect: (record) => handleRowClick(record),
  };

  const columns = [
    {
      title: "Produto",
      dataIndex: "nome",
      key: "key",
    },
  ];

  return (
    <div className="w-full flex justify-center items-center bg-white text-black">
      <div className="limited flex w-full flex-col items-center">
        <section className="flex w-full justify-around flex-col md:flex-row gap-5 md:pt-[80px] pt-[30px]">
          <span className="text-center text-blue font-[600] text-[23px] md:text-[29px] leading-[35px]">
            Selecione a categoria e produtos desejados<br></br> ao final da sua escolha, clique no botão de contato
          </span>
        </section>

        <section className="flex w-full justify-around flex-col md:flex-row md:pt-[80px] pt-[30px] pb-[60px]">
          <button
            onClick={() => {
              changeTypeProduct("limpeza");
            }}
            className={`${typeProduct === "limpeza" ? "activated-item" : "disabled-item"}`}
          >
            <img src={limpeza} className="md:w-[40px] w-[30px]" alt="limpeza" />
            <span className="text-blue text-[22px] font-medium">MATERIAL DE LIMPEZA</span>
          </button>
          <button
            onClick={() => {
              changeTypeProduct("expediente");
            }}
            className={`${typeProduct === "expediente" ? "activated-item" : "disabled-item"}`}
          >
            <img src={expediente} className="md:w-[40px] w-[30px]" alt="expediente" />
            <span className="text-blue text-[22px] font-medium">MATERIAL DE EXPEDIENTE</span>
          </button>
          <button
            onClick={() => {
              changeTypeProduct("diversos");
            }}
            className={`${typeProduct === "diversos" ? "activated-item" : "disabled-item"}`}
          >
            <img src={Diversos} className="md:w-[40px] w-[30px]" alt="diversos" />
            <span className="text-blue text-[22px] font-medium">DIVERSOS</span>
          </button>
        </section>

        <section className="w-full flex md:flex-row flex-col items-center gap-10 md:w-[90%] w-full">
          <div className="flex items-center md:w-[80%] w-full">
            <Search
              placeholder="Buscar produto"
              onChange={handleSearch}
              style={{ marginBottom: 16, fontSize: "28px" }}
            />
          </div>

          <div className="md:w-[22%] w-full flex justify-center mb-[10px] md:mb-0">
            <button
              onClick={() => setSelectedProducts([])}
              className="flex items-center gap-2 rounded-[25px] py-3 px-10 text-[18px] bg-blue mt-[-15px]"
            >
              <span className="text-offwhite">Limpar seleção</span>
            </button>
          </div>
        </section>

        <section className="md:w-[90%] w-full flex flex-col gap-[30px] items-center">
          {limpezaData.length || expedienteData.length ? (
            <Table
              rowSelection={rowSelection}
              columns={columns}
              dataSource={filteredData}
              pagination={false}
              scroll={{ x: "100%", y: 500 }}
              className="custom-table w-full"
              rowClassName={(record) =>
                selectedProducts.some((product) => product.key === record.key) ? "selected-row" : ""
              }
              onRow={(record) => ({
                onClick: () => handleRowClick(record),
              })}
            />
          ) : (
            <div className="flex flex-col mt-[20px]">
              <Spin tip="Carregando" size="large" />
              <span className="mt-[5px]">Carregando...</span>
            </div>
          )}
          {sendingOrder ? (
            <div className="flex flex-col mt-[20px]">
              <Spin tip="Carregando" size="large" />
              <span className="mt-[5px]">Enviando seu pedido...</span>
            </div>
          ) : (
            <button
              onClick={finalizeOrder}
              className="flex items-center rounded-[25px] py-3 px-10 text-[18px] bg-blue w-[260px] gap-2"
              style={{ backgroundColor: `${selectedProducts.length ? "" : "gray"}` }}
            >
              <img src={Whatsapp} className="w-[25px]" alt="whatsapp" />
              <span className="text-offwhite">Finalizar Pedido</span>
            </button>
          )}
        </section>

        <section className="flex w-full flex-col items-center md:gap-10 gap-5 pt-[120px] pb-[60px]">
          <div className="bg-orange px-4 flex justify-center items-center rounded-[8px] py-1 mb-5 md:mb-0">
            <b className="text-offwhite md:text-[45px] text-[29px] text-center md:text-start">
              MARCAS PARCEIRAS DA FOX
            </b>
          </div>
          <div className="w-full">
            <PartnerStore />
          </div>
        </section>

        <section className="flex w-full flex-col md:flex-row items-center md:gap-7 gap-6 py-[60px]">
          <div className="w-full">
            <InformationCard
              title="Qualidade garantida"
              text="Produtos de ótima qualidade"
              img={Shield}
              widthImg="60px"
            />
          </div>
          <div className="w-full">
            <InformationCard
              title="Loja oficial"
              text="Registrada e com certificado SSL"
              img={FaviconFox}
              widthImg="60px"
            />
          </div>
          <div className="w-full">
            <InformationCard
              title="Atendimento via Whatsapp"
              text="Segunda à sexta - 9h às 18h"
              img={Whatsapp}
              widthImg="80px"
            />
          </div>
        </section>
      </div>

      {/* CART */}
      <FixedCart
        sendingOrder={sendingOrder}
        finalizeOrder={finalizeOrder}
        selectedItems={selectedProducts}
        removeItem={removeItem}
        handleChangeQuantity={handleChangeQuantity}
      />
    </div>
  );
}

export default ProductsBody;
